

.aboutUsContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    padding-bottom: 1vw;
    overflow: hidden;

    background-image: url(../../images/aboutus/background.webp);
    background-color: white;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.aboutUsHeader {
    position: absolute;
    width: 60vw;
    user-select: none;
}

.textContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 22vw 5vw 1vw 10vw;
}

.textAboutUs {
    display: flex;
    position: relative;
    font-family: "NON-Natural-Grotesk-light";
    font-size: 1.25vw;
    padding-top: 1vw;
}

.umbraContainer {
    display: flex;
    position: relative;
    flex-direction: row;
}

.umbraImage {
    display: inline-block;
    position: relative;
    flex-grow: 1;
    padding: 1vw 0vw;
    user-select: none;
}

.umbraImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(40px);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease;
    border-radius: 20px;
}

.umbraImage:hover .overlay {
    opacity: 1; /* Mostrar overlay-left al pasar el mouse sobre umbraImage */
    cursor: pointer;
}

.partner-title {
    display: flex;
    font-family: "NON-Natural-Grotesk-light";
    hyphens: auto;
    font-size: 2vw;
    font-weight: bolder;
    color: white;
    margin: 1.5vw 2vw 0vw 2vw;
}

.partner-subtitle {
    display: flex;
    font-family: "NON-Natural-Grotesk-thin";
    font-size: 1.3vw;
    color: white;
    margin: 0.5vw 2vw 0vw 2vw;
}

.partner-text {
    display: flex;
    font-family: "NON-Natural-Grotesk-thin";
    font-size: 1vw;
    color: white;
    margin: 1.8vw 2vw 0vw 2vw;
}

.linkedin-icon {
    color: #ffffff;
    font-size: 2vw; 
    margin: 2vw 2vw 0vw 2vw;
}

.linkedin-icon:hover {
    color: #0e76a8;
}

.swiper {
    width: 100%;
    height: 100%;
}
  
.my-custom-swiper-container .swiper-slide {
    display: flex;
    justify-content: center; 
    text-align: center;
}
  
.my-custom-swiper-container .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.my-custom-swiper-container .swiper-pagination-fraction,
.my-custom-swiper-container .swiper-pagination-custom,
.my-custom-swiper-container .swiper-horizontal > .swiper-pagination-bullets,
.my-custom-swiper-container .swiper-pagination-bullets.swiper-pagination-horizontal {
    --swiper-pagination-bottom: 25px; /* Ajusta este valor según tu diseño */
    --swiper-pagination-top: auto;
}

.my-custom-swiper-container .swiper-pagination-bullet {
    background-color: #ffffff;
    width: 1px; 
    height: 1px; 
}

.my-custom-swiper-container .swiper-pagination-bullet-active {
    background-color: #B3B3B3;
    width: 1px; 
    height: 1px; 
}

/* movil */

@media only screen and (max-width: 1024px) {

    .aboutUsContainer {
        padding-bottom: 25px;
    }

    .aboutUsHeader {
        width: 90%;
    }

    .textContainer { 
        /* arriba | derecha | abajo | izquierda */
        margin: 35vw 40px 20px 40px;
    }

    .textAboutUs {
        font-size: 12px;
        padding-top: 10px;
    }

    .umbraImage {
        padding: 10px 0px;
    }

    .partner-title {
        font-size: 20px;
        margin: 25px 25px 10px 25px;
    }
    
    .partner-subtitle {
        font-size: 18px;
        margin: 10px 25px 5px 25px;
    }
    
    .partner-text {
        font-size: 14px;
        margin: 15px 25px 5px 25px;
        text-align: left;
    }

    .linkedin-icon {
        color: #ffffff;
        font-size: 20px; 
        margin: 20px 25px 0px 25px;
        float: left; 
    }
}