.projectContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 100%;
  
    background-image: url(../../images/project/background.webp);
    background-color: #F0F0F0;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
}

.left-blobs {
    position: absolute;
    top: 0;
    left: 0;
    height: 40vh;
    z-index: 2;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .left-blobs {
        height: 110px;
    }
}

.right-blobs {
    position: absolute;
    top: 0;
    right: 0;
    height: 45vh;
    z-index: 2;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .right-blobs {
        height: 130px;
    }
}

.titleProject {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: "Schear-Grotesk-Bold";
    font-size: 5vw;
    color: #000;
    margin: 7vh 0vw 0vh 0vw;
}

@media (max-width: 1024px) {
    .titleProject {
        font-size: 45px;
        margin: 50px 50px 0px 50px;
        text-align: center;
        line-height: 1;
    }
}


.subTitleProject {
    display: flex;
    position: relative;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: bold;
    font-size: 1.7vw;
    margin-top: 1.5vw;
}

@media (max-width: 1024px) {
    .subTitleProject {
        font-size: 18px;
        margin: 25px 10px 0px 10px;
        text-align: center;
    }
}

.subTitleProject-02 {
    display: flex;
    position: relative;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: bold;
    font-size: 18px;
    margin: 9px 0px 0px 0px;
    text-align: center;
}

.lupinImage {
    width: 15vw;
    margin: -0.15vw 0.3vw 0 0.5vw;
}

@media (max-width: 1024px) {
    .lupinImage {
        width: 169px;
        margin: -1.7px 4px 0 4px;
    }
}

.desc-project-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #000;
    margin-bottom: 4vh;
}

@media (max-width: 1024px) {
    .desc-project-container {
        width: 92%;
    }
}

.descriptionProject {
    display: block;
    width: 80%;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.1vw;
    text-align: center;
    margin: 4vh 0vw 4vh 0vw;
}

@media (max-width: 1024px) {
    .descriptionProject {
        font-size: 16px;
        width: 100%;
        margin: 30px 0px 35px 0px;
    }
}

.descriptionProject a {
    color: inherit;
    text-decoration: none;
}

.button-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-around;
    margin: 0 0 4vh 0;
    
}

@media (max-width: 1024px) {
    .button-container {
        flex-direction: column;
        margin: 0 0 2.5vh 0;
    }
}

.btn {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    
    padding: 0.7vh 4vw;
    border-radius: 0.6vw;
    background-color: #FF7B1A;
    
    font-family: "NON-Natural-Grotesk-Light";
    font-size: 1.1vw;
    color: white;
    text-align: center;
    
    cursor: pointer;
    
}

@media (max-width: 1024px) {
    .btn {
        font-size: 16px;
        margin-bottom: 15px;
    }
}

.btn:hover {
    background-color: #4B4B4B;
}

.btn:hover .click{
    color:#FF7B1A;
}


.click {
    position: absolute; 
    top: 18%;  
    right: 2%;  
    align-items: center;
    font-size: 2vw;
    color: black;
}

@media (max-width: 1024px) {
    .click {
        font-size: 33px;
    }
}

.video-container-challenge{
    display: flex;
    position: relative;
    padding-bottom: 56.25%;
    height: 100%;
    width: 100%;
    z-index: 11;
}

.video-container-challenge iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.challenge_blobs_02 {
    position: absolute;
    top: 85vh;
    left: 0;
    height: 45vw;
    z-index: 50;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .challenge_blobs_02 {
        display: none;
    }
}

.contestContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-items: center;
    width: 100%;
    margin-top: -10vh;
    overflow: hidden;
    z-index: 10;
}

@media (max-width: 1024px) {
    .contestContainer  {
        padding-top: 0vw;
        margin-top: 0;
    }
}

.challenge_bkg_01 {
    position: absolute;
    width: 100%;
    user-select: none;
    pointer-events: none;
    z-index: 10;
}

.challenge_bkg_02 {
    position: absolute;
    width: 100%;
    user-select: none;
    pointer-events: none;
    top: -18.4vw;
    z-index: 20;
}

@media (max-width: 1024px) {
    .challenge_bkg_02  {
        top: 2vw;
    }
}

.getTheRigContainer {
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    z-index: 20;
}

.getTheRigContainer:hover .clickGetTheRig{
    color:#FF7B1A;
}

.getTheRig {
    display: flex;
    width: 44vw;
    margin: 13.3vw 1vw 0vw 1vw;
    z-index: 30;
}

@media (max-width: 1024px) {
    .getTheRig  {
        width: 80%;
    }
}

.clickGetTheRig {
    position: absolute; 
    bottom: 22%;
    right: 27%;  
    font-size: 2vw;
    color: black;
    z-index: 30;
}

@media (max-width: 1024px) {
    .clickGetTheRig  {
        bottom: 25%;
        right: 28%;  
        font-size: 5vw;
    }
}

.challenge_bkg_03 {
    position: absolute;
    width: 100%;
    user-select: none;
    pointer-events: none;
    top: -18.9vw;
    z-index: 30;
}

@media (max-width: 1024px) {
    .challenge_bkg_03  {
        top: 1vw;
    }
}

.contestTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: "Schear-Grotesk-Bold";
    font-size: 5vw;
    color: #000;
    margin: 4vw 0vw 0vw 0vw;
    z-index: 40;
}

@media (max-width: 1024px) {
    .contestTitle  {
        margin: 5vw 0 0 0;
        font-size: 45px;
    }
}

.contestTitle::after {
    content: '';
    display: block;
    width: 250%;
    height: 2px;
    background-color: #000;
    margin: 3vw 0 3vw 0vw;
}

@media (max-width: 1024px) {
    .contestTitle::after  {
        width: 130%;
        font-size: 45px;
        height: 1px;
    }
}

.contestSubtitle {
    display: flex;
    align-items: center;
    position: relative;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: bold;
    font-size: 2vw;
    color: #000;
    margin: 1vw 0vw 0vw 0vw;
    z-index: 40;
}

@media (max-width: 1024px) {
    .contestSubtitle  {
        font-size: 20px;
        margin: 2vw 0vw 1.5vw 0vw;
    }
}

.contestDetails {
    display: block;
    width: 60%;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.1vw;
    text-align: center;
    margin: 2vw 0vw 0vw 0vw;
    z-index: 40;
}

@media (max-width: 1024px) {
    .contestDetails  {
        font-size: 16px;
        width: 90%;
        margin: 3vw 0vw 0vw 0vw;
    }
}

.lupin_logo_black {
    display: flex;
    width: 30vw;
    margin: 3vw 0vw 1vw 0vw;
    z-index: 40;
}

@media (max-width: 1024px) {
    .lupin_logo_black {
        width: 250px;
        margin: 5vw 0vw 3vw 0vw;
    }
}

.button-container-02 {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: space-around;
    flex-direction: row;
    margin: 2vw 0 4vw 0;
    z-index: 40;
    background-color: #f7f6f2;
}


.button-container-02 button {
    display: inline-block;
    padding: 1vh 6.5vw;
    font-size: 1.8vw;
    border-radius: 1vw;
}

@media (max-width: 1024px) {
    .button-container-02 button {
        font-size: 16px;
        padding: 1vh 8vw;
        margin-bottom: 15px;
    }
}

.clockContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 63%;
    border-top: 3px solid #000;
    z-index: 40;
    padding: 4vw 0 4vw 0;
    font-family: "Schear-Grotesk-Bold";
}

@media (max-width: 1024px) {
    .clockContainer {
        width: 68%;
        border-top: 1px solid #000;
        padding: 7.5vw 0 4vw 0;
    }
}

.lupin_logo_white {
    display: flex;
    width: 30vw;
    margin: 15vw 0vw 1vw 0vw;
    z-index: 40;
}

.challenge_bkg_04 {
    position: absolute;
    user-select: none;
    bottom: -1px;
    width: 100%;
    z-index: 45;
}

.jury-container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    margin: 2vw 0 0 0;
    z-index: 40;
}

.juryTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: "Schear-Grotesk-Bold";
    font-size: 5vw;
    color: #000;
    margin: 2vw 0vw 0vw 0vw;
    z-index: 40;
}

@media (max-width: 1024px) {
    .juryTitle {
        margin: 4vw 0 0 0;
        font-size: 45px;
    }
}

.juryTitle::after {
    content: '';
    display: block;
    width: 480%;
    height: 2px;
    background-color: #000;
    margin: 3vw 0 3vw 0;
}

@media (max-width: 1024px) {
    .juryTitle::after {
        width: 250%;
        height: 1px;
        margin: 4.6vw 0 3vw 0;
    }
}

.cardsContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    z-index: 40;
    margin-top: 0.2vw;
}

.juryCard {
    display: inline-block;
    position: relative;
    flex-grow: 1;
    padding: 1vw 0vw;
    user-select: none;
}

.juryCard img {
    width: 100%;
    object-fit: contain;
}

.juryCard:hover .overlay-challenger {
    opacity: 1;
    cursor: pointer;
}

.overlay-challenger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(40px);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
}

.jury-name {
    display: flex;
    font-family: "NON-Natural-Grotesk-light";
    font-size: 1.5vw;
    font-weight: bolder;
    text-align: center;
    color: white;
}

@media (max-width: 1024px) {
    .jury-name {
        font-size: 26px;
        margin-bottom: 5px;
    }
}

.jury-charge {
    display: flex;
    font-family: "NON-Natural-Grotesk-thin";
    font-size: 1vw;
    color: white;
    margin-top: 0.5vw;
}

@media (max-width: 1024px) {
    .jury-charge {
        font-size: 20px;
        margin-bottom: 20px;

    }
}

.jury-logo-01 {
    width: 65%;
    aspect-ratio: 837 / 492;
    margin-top: 1.5vw;
    z-index: 40;
    background-image: url('../../images/challenge/dreamworks_logo.webp');
    background-size: contain;
    background-repeat: no-repeat;
}

.jury-logo-02 {
    width: 70%;
    aspect-ratio: 1000 / 300;
    margin-top: 1.3vw;
    z-index: 40;
    background-image: url('../../images/challenge/lupin_house_white.webp');
    background-size: contain;
    background-repeat: no-repeat;
}

.jury-logo-03 {
    width: 70%;
    aspect-ratio: 2100 / 550;
    margin-top: 1.3vw;
    z-index: 40;
    background-image: url('../../images/challenge/framestore_logo.webp');
    background-size: contain;
    background-repeat: no-repeat;
}

.jury-logo-04 {
    width: 50%;
    aspect-ratio: 435 / 398;
    margin-top: 1.3vw;
    z-index: 40;
    background-image: url('../../images/challenge/little_logo.webp');
    background-size: contain;
    background-repeat: no-repeat;
}

.jury-logo-05 {
    width: 60%;
    aspect-ratio: 3456 / 800;
    margin-top: 1.5vw;
    z-index: 40;
    background-image: url('../../images/challenge/firelight_logo.webp');
    background-size: contain;
    background-repeat: no-repeat;
}

.linkedin-jury {
    position: absolute; 
    bottom: 6%;  
    left: 8%;  

    color: #ffffff;
    font-size: 2vw;
    margin-top: 1vw;
}

@media (max-width: 1024px) {
    .linkedin-jury {
        font-size: 35px;
    }
}

.linkedin-jury:hover {
    color: #0e76a8;
}

.disclaimerContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    margin: 3vw 0 0 0;
    z-index: 50;
}

.disclaimerContainer::before {
    content: '';
    position: absolute;
    top: 0; /* Posiciona el borde en la parte superior del contenedor */
    width: 63%; /* Ancho del borde */
    height: 2px; /* Altura del borde */
    background-color: #000; /* Color del borde */
    left: 50%; /* Centra horizontalmente */
    transform: translateX(-50%); /* Ajuste para centrar el borde */
}

@media (max-width: 1024px) {
    .disclaimerContainer::before {
        width: 75%;
        height: 1px;
    }
}

@media (max-width: 1024px) {
    .disclaimerContainer {
        margin: 28px 0 3vw 0;
    }
}

.disclaimerAlert {
    display: flex;
    width: 5vw;
    margin: 2vw 0 2vw 0;
    z-index: 50;
}

@media (max-width: 1024px) {
    .disclaimerAlert {
        margin: 28px 0 10px 0;
        width: 10vw;
    }
}

.disclaimerTitle {
    display: flex;
    align-items: center;
    position: relative;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: bold;
    font-size: 1.5vw;
    color: #000;
    z-index: 50;
}

@media (max-width: 1024px) {
    .disclaimerTitle {
        font-size: 20px;
    }
}

.disclaimerText {
    display: flex;
    width: 50%;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.1vw;
    text-align: center;
    margin: 4vh 0vw 4vh 0vw;
    z-index: 50;
}

@media (max-width: 1024px) {
    .disclaimerText {
        font-size: 16px;
        width: 90%;
    }
}

.challenge_blobs_03 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60vh;
    z-index: 40;
    user-select: none;
}

@media (max-width: 1024px) {
    .challenge_blobs_03 {
        display: none;
    }
}

.challenge_blobs_04 {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60vh;
    z-index: 40;
    user-select: none;
}

@media (max-width: 1024px) {
    .challenge_blobs_04 {
        display: none;
    }
}

.swiper {
    width: 100%;
    height: 100%;
    margin-top: 16px;
    z-index: 40;
}
  
.challenger-swiper-container .swiper-slide {
    display: flex;
    justify-content: center; 
    text-align: center;
}
  
.challenger-swiper-container .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.challenger-swiper-container .swiper-pagination-fraction,
.challenger-swiper-container .swiper-pagination-custom,
.challenger-swiper-container .swiper-horizontal > .swiper-pagination-bullets,
.challenger-swiper-container .swiper-pagination-bullets.swiper-pagination-horizontal {
    --swiper-pagination-bottom: 25px; /* Ajusta este valor según tu diseño */
    --swiper-pagination-top: auto;
}

.challenger-swiper-container .swiper-pagination-bullet {
    background-color: #ffffff;
    width: 1px; 
    height: 1px; 
}

.challenger-swiper-container .swiper-pagination-bullet-active {
    background-color: #B3B3B3;
    width: 1px; 
    height: 1px; 
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: auto;
}
  
.modal-content {
    width: 100%;
    max-width: 30vw; 
    background-color: white; 
    padding: 1.5vw; 
    border-radius: 1vw; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    height: auto !important;
}

@media (max-width: 1024px) {
    .modal-content {
        max-width: 450px;
        padding: 10px;
        margin: 0 10px 0 10px;
    }
}

.form-group {
    display: flex; 
    justify-content: flex-start;
    align-items: baseline; 
    margin-bottom: 1vw;
    width: 100%;
}

@media (max-width: 1024px) {
    .form-group {
        margin-bottom: 10px;
    }
}

.form-group label {
    flex: 0 1 auto;
    margin-right: 1vw; 
    min-width: 8vw;
}

@media (max-width: 1024px) {
    .form-group label {
        margin-right: 8px;
        min-width: 80px;
    }
}

.form-group input {
    flex: 1;
    padding: 0 0.5vw;
    border: 1px solid #ccc; 
    font-family: "NON-Natural-Grotesk-Light";
    font-size: 1.3vw;
    border-radius: 3px;
}

@media (max-width: 1024px) {
    .form-group input {
        padding: 0 5px;
        font-size: 10px;
    }
}

input::placeholder {
    color: lightgray;
    opacity: 0.3;
}

.form-title{
    display: flex;
    font-family: "Schear-Grotesk-Bold";
    font-size: 4vw;
    color: #000;
    margin-bottom: 1vw;
}

@media (max-width: 1024px) {
    .form-title {
        font-size: 40px;
        margin-bottom: 10px;
    }
}

.form-label {
    display: flex;
    font-family: "NON-Natural-Grotesk-Light";
    font-size: 1.1vw;
}

@media (max-width: 1024px) {
    .form-label {
        font-size: 12px;
    }
}

.terms-text {
    display: inline;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1vw;
    text-align: justify;
    margin: 0.5vw 0 0.5vw 0;
}

@media (max-width: 1024px) {
    .terms-text {
        font-size: 10px;
    }
}

.terms-content {
    margin: 0 0 2vw 0;
    padding: 0 1vw 0 1vw;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 0.7vw;
    overflow-y: auto;
    max-height: 10vw;
}

@media (max-width: 1024px) {
    .terms-content {
        margin: 0 0 12px 0;
        padding: 0 5px 0 5px;
        font-size: 8px;
        max-height: 100px;
    }
}

.terms-container {
    max-height: 0; /* Oculto por defecto */
    overflow: hidden; /* Evita que se muestre el contenido */
    transition: max-height 0.5s ease-in-out; /* Transición suave */
}

.terms-container.visible {
    max-height: 500px; /* Ajusta el valor según el contenido que esperas */
    transition: max-height 0.5s ease-in-out; /* Transición suave */
}


.terms-content ul ul {
    margin-top: 0.1vw;
}

@media (max-width: 1024px) {
    .terms-content ul ul {
        margin-top: 3px;
    }
}

button[type='submit'] {
    width: 20vw; 
    padding: 0.5vw;
    font-family: "NON-Natural-Grotesk-Light";
    font-size: 1.1vw;
    border: none; 
    border-radius: 5px;
    background-color: #FF7B1A;
    color: white;
    cursor: pointer; 
    margin: 0 auto 0.7vw; 
    display: block;
}

@media (max-width: 1024px) {
    button[type='submit'] {
        width: 200px;
        padding: 5px;
        font-size: 12px;
        margin: 0 auto 6px; 
    }
}

.thanks-text {
    display: inline;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.5vw;
    text-align: justify;
    margin: 0 0 0.5vw 0;
}

@media (max-width: 1024px) {
    .thanks-text {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

.creditsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 63%;
    margin: 5vh 0vw 5vh 0vw;
    z-index: 50;
}

@media (max-width: 1024px) {
    .creditsContainer {
        font-size: 12px;
        margin: 10px 0 30px 0;
        width: 85%;
    }
}

.creditsContainer:hover {
    cursor: pointer;
}

.creditsTitleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #000;
    margin-bottom: 1.5vh;
}

@media (max-width: 1024px) {
    .creditsTitleContainer {
        border-bottom: 1px solid #000;
        font-size: 12px;
        margin-bottom: 15px;
    }
}

.credits {
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: bold;
    font-size: 1.1vw;
    padding-bottom: 1vh;
}

@media (max-width: 1024px) {
    .credits {
        font-family: "NON-Natural-Grotesk-Light";
        font-weight: bold;
        font-size: 17px;
        padding-bottom: 10px;
    }
}

.creditsIcon {
    font-size: 1.1vw;
}

@media (max-width: 1024px) {
    .creditsIcon {
        font-size: 17px;
    }
}

.creditsIcon:hover {
    cursor: pointer;
}

.creditsDetails {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.creditsDetails.active {
    max-height: 2000px; 
}

.creditsText {
    text-align: left;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.1vw;
    padding: 5px 0px;
}

@media (max-width: 1024px) {
    .creditsText {
        font-size: 16px;
        padding: 3px 0px;
    }
}