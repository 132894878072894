footer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: auto;
    z-index: 9999;
}
  
@media (max-width: 1024px) {
    footer {
        max-height: 400px;
    }
}

.footer-banner-container {
    display: flex;
    width: 100%;
    background-color: white;
    overflow: hidden;
}

.banner-text {
    font-family: "Schear-Grotesk-Bold";
    font-size: 7vw;
    color: #d1d1d3;
    white-space: nowrap;
    animation: scroll 30s linear infinite;
    -webkit-animation: scroll 30s linear infinite;
    user-select: none;
}

@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@media (max-width: 1024px) {
    .banner-text {
        font-size: 5.5rem;
    }
}

.footer-social-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    background-color: #1d1d1f;
}

@media (max-width: 1024px) {
    .footer-social-container {
      min-height: 150px;
    }
}

.social {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding: 0vw 0 1.5vw 0;
}
.social-text {
    display: inline;
    font-family: "ABC-Monument-Grotesk-Light";
    font-size: 1vw;
    color: #f5f5f7;
    text-decoration: none;
    padding: 1vw 0 0 1vw;
}

.social-text a {
    text-decoration: none;
    color: #f5f5f7;
}

@media (max-width: 1024px) {
    .social-text {
      font-size: 14px;
      padding: 6px 0 6px 20px;
    }
}
  

.email {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: flex-end;
    padding: 0vw 0 1.3vw 0;
    margin-top: 3.5vw;
}

@media (max-width: 1024px) {
    .email {
        margin-top: 30px;
    }
}

.email-text {
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 1vw;
    color: #f5f5f7;
    padding: 0.5vh 1.5vw 0vh 0vw;
}

@media (max-width: 1024px) {
    .email-text {
      font-size: 14px;
      padding: 3px 18px 5px 20px;
    }
}