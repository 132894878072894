.errorContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 1920px;
    margin: auto;
    height: 1200px;
  
    background-image: url(../../images/error/background.webp);
    background-color: white;
    background-size: 100% 100%;
    background-position: top;
    background-repeat: no-repeat;
}

.errorBlobsContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    max-width: 1920px;
    height: 65%;
    width: 50%;
    margin: auto 140px 150px 80px;
    align-items: center;

    background-image: url(../../images/error/blobs.webp);
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.alertContainer {
    display: flex;
    position: relative;
    max-width: 1920px;
    height: 65%;
    width: 25%;
    margin-top: auto;

    background-image: url(../../images/error/error.webp);
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.errorTitle {
    display: flex;
    position: relative;
    font-family: "Schear-Grotesk-Bold";
    font-size: 150px;
    color: #000;
    margin-top: 180px;
}

.errorText-01 {
    display: inline;
    font-family: "ABC-Monument-Grotesk-Light";
    font-size: 26px;
    text-align: center;
    width: 60%;
    color: #000;
    margin-top: 10px;

}

.errorText-02 {
    display: inline;
    font-family: "ABC-Monument-Grotesk-Light";
    font-size: 26px;
    text-align: center;
    width: 60%;
    color: #000;
    margin-top: 30px;
}

/* movil */

@media only screen and (max-width: 1024px) {

    .errorContainer {
        height: 550px;
        align-items: center;
        padding: 10px;
        background-image: url(../../images/error/background_movil.webp);
    }

    .errorBlobsContainer {
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .alertContainer {
        display: none;
    }

    .errorTitle {
        font-size: 35px;
        margin: 95px 20px 15px 40px;
    }

    .errorText-01 {
        font-size: 12px;
        margin: 0px 0px 10px 10px;
    }

    .errorText-02 {
        font-size: 12px;
        padding-bottom: 0px;
        margin: 0px 0px 0px 0px;
    }
}