.home_blobs_01 {
    position: absolute;
    top: 0;
    right: 0;
    height: 45vh;
    z-index: 50;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .home_blobs_01 {
        height: 10%;
    }
}

.home_blobs_02 {
    position: absolute;
    top: 25vh;
    left: 0;
    height: 100vh;
    z-index: 50;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .home_blobs_02 {
        display: none;
    }
}

.home_blobs_03 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100vh;
    z-index: 50;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .home_blobs_03 {
        display: none;
    }
}

.home_blobs_04 {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 110vh;
    z-index: 50;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .home_blobs_04 {
        display: none;
    }
}

.home_background_01 {
    position: absolute;
    width: 100%;
    user-select: none;
    pointer-events: none;
    z-index: 10;
}


.home_background_02 {
    position: absolute;
    width: 100%;
    user-select: none;
    pointer-events: none;
    top: -53vw;
    z-index: 20;
}

@media (max-width: 1024px) {
    .home_background_02  {
        top: -53vw;
    }
}

.home_background_03 {
    position: absolute;
    width: 100%;
    user-select: none;
    pointer-events: none;
    top: -30vw;
    z-index: 30;
}

.homeContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    overflow: hidden;
}

.videoContainer {
    display: flex;
    position: relative;
    width: 100%;
    height: 89vh;
    z-index: 0;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .videoContainer {
        height: 600px;
        top: -35px
    }
}

.playIcon {
    position: absolute;
    bottom: 13vw; 
    right: 2vw; 
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
    z-index: 900;
}

@media (max-width: 1024px) {
    .playIcon {
        font-size: 22px;
        bottom: 110px; 
        right: 25px; 
    }
}

.pauseIcon {
    position: absolute;
    bottom: 10.5vw; 
    right: 2vw; 
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
    z-index: 900;
}

@media (max-width: 1024px) {
    .pauseIcon {
        font-size: 22px;
        bottom: 80px; 
        right: 25px; 
    }
}

.speakerIcon {
    position: absolute;
    bottom: 8vw; 
    right: 2vw; 
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
    z-index: 900;
}

@media (max-width: 1024px) {
    .speakerIcon {
        font-size: 20px;
        bottom: 50px; 
        right: 25px; 
    }
}

.screenIcon {
    position: absolute;
    bottom: 5.5vw; 
    right: 2vw;
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
    z-index: 900;
}

@media (max-width: 1024px) {
    .screenIcon {
        font-size: 20px;
        bottom: 15px; 
        right: 25px; 
    }
}

.helloContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
    margin-top: -10vh;
    padding-bottom: 7vw;
    overflow: hidden;
    z-index: 10;
}

@media (max-width: 1024px) {
    .helloContainer  {
        padding-top: 0vw;
    }
}

.titleWelcomeMessage {
    display: inline;
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 2.5rem;
    font-weight: bold;
    color: #000;
    margin: 10.6vw 1vw 2.6vw 1vw;
    z-index: 20;
}

@media (max-width: 1024px) {
    .titleWelcomeMessage {
        padding: 15px 0 15px 0;
    }
}

.textWelcomeMessage {
    display: inline;
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 1.3rem;
    color: #000;
    margin: 0vh 18vw 0vh 18vw;
    z-index: 20;
}

@media (max-width: 768px) {
    .textWelcomeMessage {
        font-size: 1rem;
    }
}

@media (max-width: 1024px) {
    .textWelcomeMessage {
        margin: 0px 20px 15px 20px;
  }
}

.studiesContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8vw 0 8vw 0;
    background-color: #FDFEFE;
    z-index: 10;
}

.titleCaseStudies {
    display: inline;
    font-family: "Schear-Grotesk-Bold";
    font-size: 4rem;
    color: #000;
    margin: 0vw 0vw 3.5vw 0vw;
    z-index: 20;
}

@media (max-width: 1024px) {
    .titleCaseStudies {
        font-size: 3rem;
        padding: 10px 0px 10px 0px;
  }
}

.cardImageHome {
    display: flex;
    width: 100%;
    user-select: none;
    transition: filter 0.3s ease;
}

.cardImageHome:hover {
    filter: brightness(1.2);
}

@media (max-width: 1024px) {
    .cardImageHome:hover {
        filter: none;
    }
}

.cardTitleHome {
    display: Flex;
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 1.3rem;
    font-weight: bold;
    margin: 1vh 0vw 0vw 0vw;
}

@media (max-width: 1024px) {
    .cardTitleHome {
        margin: 10px 0px 0px 0px;  
    }
}

.cardSubTitleHome {
    display: flex;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.2vw;
    margin: 0.5vw 0px 0px 0px;
}

@media (max-width: 1024px) {
    .cardSubTitleHome {
        font-size: 20px;
        margin: 0.5px 0px 0px 0px;  
    }
}

.cardDescriptionHome {
    display: flex;
    font-family: "NON-Natural-Grotesk-light";
    font-weight: normal;
    font-size: 32px;
    text-align: left;
    padding-bottom: 100px;
    margin: 40px 30px 0px 30px;
}

.clientContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 14vw 0 0 0;
    z-index: 30;
    background-color: #F7F6F1;
}

.titleClients {
    display: inline;
    font-family: "Schear-Grotesk-Bold";
    font-size: 4rem;
    color: #000;
    margin: 0vw 1vw 2vw 1vw;
    z-index: 30;
}

@media (max-width: 1024px) {
    .titleClients {
       padding: 10px 0px 10px 0px;
       font-size: 3rem;
    }
}

.titlePartners {
    display: inline;
    font-family: "Schear-Grotesk-Bold";
    font-size: 4rem;
    color: #000;
    margin: 1vw 1vw 1.4vw 1vw;
    z-index: 30;
}

@media (max-width: 1024px) {
    .titlePartners {
       padding: 10px 0px 10px 0px;
       font-size: 3rem;
    }
}

.textClients {
    display: inline;
    font-family: "ABC-Monument-Grotesk-Light";
    font-size: 1.3vw;
    color: #000;
    margin: 0vw 1vw 0vw 1vw;
    z-index: 30;
}

@media (max-width: 1024px) {
    .textClients {
        font-size: 18px;
    }
}

.logosContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 30;
    background-color: #F7F6F1;
}

@media (max-width: 1024px) {
    .logosContainer {
        margin-bottom: 10px;
    }
}

.logoClientsSmall {
    width: 7vw;
    user-select: none;
    z-index: 30;
    margin-left: 1vw;
    margin-bottom: 1vw;
}

@media (max-width: 1024px) {
    .logoClientsSmall {
        margin-left: 0vw;
        width: 120px;
    }
}

.logoClientsNormal {
    width: 10vw;
    user-select: none;
    z-index: 30;
    margin-right: 2vw;
}

@media (max-width: 1024px) {
    .logoClientsNormal {
        width: 150px;
    }
}

.logoClientsBig {
    width: 12vw;
    user-select: none;
    z-index: 30;
    margin-right: 2vw;
}

@media (max-width: 1024px) {
    .logoClientsBig {
        width: 160px;
    }
}

.logoClientsXL {
    width: 16vw;
    user-select: none;
    z-index: 30;
    
}

@media (max-width: 1024px) {
    .logoClientsXL {
        width: 230px;
    }
}


.GetInTouchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    z-index: 30;
}

.getInTouch {
    display: flex;
    width: 80vh;
    margin: 4.1vw 1vw 0vw 1vw;
    z-index: 30;
}

@media (max-width: 1024px) {
    .getInTouch {
        margin: 30px 0px 0px 0px;
        width: 80%;
    }
}

.navContainer {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%; 
}

.navSlider {
    display: flex;
    height: 100%;
    width: 33.3333%;
    z-index: 900;
}

.navSliderCenter {
    display: flex;
    height: 100%;
    width: 33.3333%;
}

.slider {
    width: 100%;
    z-index: 20;
}

.slick-slider .slick-dots {
    bottom: -30px;
}

.slick-slide {
    transition: transform 1s ease;
}

.slick-slide:not(.slick-center) {
    transform: scale(0.9);
}