.careersContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: right;
    margin: auto;
    height: 140vh;
  
    background-image: url(../../images/careers/background.webp);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

@media (min-width: 2560px) {
    .careersContainer {
        height: 120vh;
    }
}

.blobsContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
  
    background-image: url(../../images/careers/blobs.webp);
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;

}

.title {
    display: inline;
    font-family: "Schear-Grotesk-Bold";
    font-size: 3.5vw;
    color: #000;
    margin: 3vh 3vw 3vh 10vw;
}

.textCareers-01 {
    display: inline;
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 1.2vw;
    color: #000;
    margin: 0vh 3vw 0.4vh 0vw;
}

.textCareers-02 {
    display: inline;
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 1.2vw;
    color: #000;
    padding-bottom: 1vh;
    margin: 0vh 3vw 1vh 0vw;
}

.subTitle {
    display: inline;
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 2.5vw;
    color: #000;
    margin: 3vh 3vw 2vh 3vw;
}

.textVacancies {
    display: flex;
    font-family: "ABC-Monument-Grotesk-Light";
    justify-content: flex-end;
    font-size: 2vw;
    color: #000;
    padding: 2vh 0vw 1vh 0vw;
    margin: 0vh 3vw 1vh 0vw;
}

.textVacancies:hover {
    font-weight: bold;
    cursor: pointer;
}

.selected {
    font-weight: bold;
}

.vacanciesContainer{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 60%;
    margin-left: auto;
}

.detailsVacancies {
    display: flex;
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 1.5vw;
    color: #000;
    margin: 0vh 3vw 1vh 0vw;
}

.umbra {
    position: absolute;
    bottom: -165px;
    right: 3vw;
    height: auto;
    width: 140px;
    z-index: 2;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .umbra {
        bottom: -108px;
        right: 3vw;
        height: auto;
        width: 100px;
    }
}

/* movil */

@media only screen and (max-width: 1024px) {

    .careersContainer {
        height: 650px;
    }

    .blobsContainer {
        background-image: url(../../images/careers/blobs-movil.webp);
        background-size: 100%;
    }

    .title {
        font-size: 35px;
        margin: 20px 20px 15px 20px;
    }

    .textCareers-01 {
        font-size: 12px;
        margin: 0px 20px 3px 0px;
    }

    .textCareers-02 {
        font-size: 12px;
        padding-bottom: 0px;
        margin: 0px 20px 5px 0px;
    }

    .subTitle {
        font-size: 16px;
        margin: 10px 20px 10px 20px;
    }

    .textVacancies {
        font-size: 13px;
        padding: 5px 0px 0px 0px;
        margin: 0px 20px 10px 0px;
    }

    .vacanciesContainer{
        width: 75%;
    }

    .detailsVacancies {
        font-size: 12px;
        margin: 0px 20px 5px 0px;
    }
  }