.ourWorkContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: auto;
    
    background-color: white;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

@media (max-width: 1024px) {
    .ourWorkContainer {
        background-image: url(../../images/ourwork/background.webp);
    }
}

.blobs_01 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 25vh;
    z-index: 2;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .blobs_01 {
        display: none;
    }
}

.blobs_02 {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    width: 8vw;
    z-index: 2;
    user-select: none;
    pointer-events: none;
    transform: translateY(-50%);
}

.blobs_03 {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20vw;
    z-index: 2;
    user-select: none;
    pointer-events: none;
    transform: scaleX(-1);
}

@media (max-width: 1024px) {
    .blobs_03 {
        display: none;
    }
}

.blobs_04 {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .blobs_04 {
        display: none;
    }
}

.titleOurWork {
    display: flex;
    font-family: "Schear-Grotesk-Bold";
    font-size: 4vw;
    color: #000;
    margin: 6vh 0vw 0vh 0vw;
}

@media (max-width: 1024px) {
    .titleOurWork { 
        font-size: 40px;
        margin: 30px 0px 0px 0px;
    }
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 0.5vw;
    justify-items: center;
    margin: 6vh 0.5vw 25vh 0.5vw;
}

@media (max-width: 1024px) {
    .cardContainer { 
        max-width: 90vw;
        grid-row-gap: 15px;
        margin: 30px 0px 40px 0px;
    }
}

.card {
    display: grid;
    grid-template-rows: auto 1fr auto; /* Primera fila para el título, segunda para la imagen, tercera para el subtítulo */
    overflow: hidden; /* Para evitar que el texto sobresalga */
}

.cardImage {
    width: 100%;
}

.cardImage:hover {
    filter: brightness(1.2);
}

.cardTitle {
    display: Flex;
    font-family: "NON-Natural-Grotesk-Regular";
    font-size: 0.9vw;
    font-weight: bold;
    margin: 0.8vh 0vw 0vh 0vw;
}

@media (max-width: 1024px) {
    .cardTitle {
        font-size: 20px;
        margin: 5px 0px 0px 0px;
    }
}

.cardSubTitle {
    display: flex;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 0.7vw;
    margin: 0.5vh 0vw 1vh 0vw;
}

@media (max-width: 1024px) {
    .cardSubTitle{
        font-size: 15px;
        margin: 3px 0px 10px 0px;
    }
}

.cardDescription {
    display: flex;
    font-family: "NON-Natural-Grotesk-light";
    font-weight: normal;
    font-size: 32px;
    text-align: left;
    padding-bottom: 100px;
    margin: 40px 30px 0px 30px;
}

/* movil */

@media only screen and (max-width: 1024px) {
        
    .cardDescription {
        font-size: 15px;
        margin: 10px 20px 50px 20px;
        padding-top: 10px;
        padding-bottom: 0px;
    }

}
