.projectContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 100%;
  
    background-image: url(../../images/project/background.webp);
    background-color: #F0F0F0;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
}

.left-blobs {
    position: absolute;
    top: 0;
    left: 0;
    height: 40vh;
    z-index: 2;
    user-select: none;
}

.right-blobs {
    position: absolute;
    top: 0;
    right: 0;
    height: 45vh;
    z-index: 2;
    user-select: none;
}

.titleProject {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: "Schear-Grotesk-Bold";
    font-size: 5vw;
    color: #000;
    margin: 7vh 0vw 0vh 0vw;
}

.subTitleProject {
    display: flex;
    position: relative;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: bold;
    font-size: 1.7vw;
    margin-top: 20px;
}

.yearProject {
    display: flex;
    position: relative;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.6vw;
    letter-spacing: 0.3vw;
    margin-top: 1.5vh;
}

.desc-project-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #000;
}

.descriptionProject {
    display: flex;
    width: 80%;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.3rem;
    text-align: center;
    margin: 4vh 0vw 4vh 0vw;
}

.picContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4vh 0vw 4vh 0vw;
    user-select: none;
}

.line-01-container {
    display: flex;
    flex-direction: row;
}

.line-02-container {
    display: flex;
    flex-direction: row;
}

.sneakpic-desktop {
    display: flex;
    width: 560px;
    margin: 1vw;
    
    user-select: none;
    transition: filter 0.3s ease;
}

.sneakpic-desktop:hover {
    cursor: pointer;
    filter: brightness(120%);
}

.sneakpic-movil {
    display: flex;
    width: 560px;
    margin: 1vw;
    user-select: none;
    transition: filter 0.3s ease;
}

.sneakpic-movil:hover {
    cursor: pointer;
    filter: brightness(120%);
}


.detailsProject {
    display: flex;
    flex-direction: column;
    width: 65%;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.3rem;
    margin: 0px 0px 40px 0px;
}

.video-container{
    display: flex;
    position: relative;
    width: 100%;
}

.bannerpic {
    display: flex;
    width: 100%;
    height: 700px;
    object-fit: cover;
    margin: 15px;
    user-select: none;
}

.title-project-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 60%;
    margin: 80px 0px;
}

.title-02-project {
    display: flex;
    width: 30%;
    font-family: "ABC-Monument-Grotesk-Light";
    font-weight: bold;
    font-size: 35px;
}

.comments-project {
    display: flex;
    flex-direction: column;
    width: 60%;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 35px;
    margin: 50px 0px 40px 0px;
}

.sneakpic-02 {
    display: flex;
    width: 1140px;
    margin-bottom: 100px;
    user-select: none;
}

.projectPlayIcon {
    position: absolute;
    bottom: 190px; 
    right: 51px; 
    font-size: 40px;
    cursor: pointer;
    color: #fff;
    z-index: 2;
}

.projectPauseIcon {
    position: absolute;
    bottom: 135px; 
    right: 51px; 
    font-size: 40px;
    cursor: pointer;
    color: #fff;
    z-index: 2;
}

.projectScreenIcon {
    position: absolute;
    bottom: 25px; 
    right: 51px; 
    font-size: 40px;
    cursor: pointer;
    color: #fff;
    z-index: 2;
}

.projectSpeakerIcon {
    position: absolute;
    bottom: 80px; 
    right: 50px; 
    font-size: 40px;
    cursor: pointer;
    color: #fff;
    z-index: 2;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}
  
.modal-content {
    width: 1280px;
    height: 720px;
}
  
.modal-content img {
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: contain;
}

.share-container {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 40px 0px;
    
}


.share-title {
    display: flex;
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 20px;
}

.share-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: nones;
}

.facebook {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3b5998;
    color: white; 
    font-size: 25px;
    padding: 8px;
}

.twitter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1DA1F2; 
    color: white;
    font-size: 25px;
    padding: 8px;
}

.whatsapp {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #25D366; 
    color: white;
    font-size: 25px;
    padding: 8px;
}

.telegram {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #229ED9; 
    color: white;
    font-size: 25px;
    padding: 8px;
}

.linkedin {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0e76a8; 
    color: white;
    font-size: 25px;
    padding: 8px;
}

.credits-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 5vh 0vw 5vh 0vw;
}

.credits-container:hover {
    cursor: pointer;
}

.creditsTitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000;
    margin-bottom: 1.5vh;
}

.credits {
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: bold;
    font-size: 1.5rem;
    padding-bottom: 1vh;
}

.creditsIcon {
    font-size: 1.5rem;
}

.creditsIcon:hover {
    cursor: pointer;
}

.creditsDetails {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;
}

.creditsDetails.active {
    max-height: 1000px; 
}

.creditsText {
    font-family: "NON-Natural-Grotesk-Light";
    font-weight: normal;
    font-size: 1.3rem;
    padding: 5px 0px;
}

/* movil */

@media only screen and (max-width: 1024px) {

    .left-blobs {
        height: 110px;
    }

    .right-blobs {
        height: 130px;
    }

    .titleProject {
        font-size: 45px;
        color: #000;
        margin: 50px 0px 0px 0px;
    }

    .subTitleProject {
        font-size: 20px;
        
    }

    .yearProject {
        font-size: 14px;
        letter-spacing: 5px;
        margin-top: 10px;
    }

    .desc-project-container {
        width: 92%;
    }

    .descriptionProject {
        font-size: 16px;
        width: 90%;
        margin: 30px 0px 35px 0px;
    }

    .picContainer {
        margin: 13px 15px 13px 15px;
    }

    .line-01-container {
        display: flex;
        flex-direction: column;
    }

    .line-02-container {
        display: flex;
        flex-direction: column;
    }

    .sneakpic-desktop {
        display: none;
        width: 100%;
        margin: 10px 0px;
    }

    .sneakpic-movil {
        width: 100%;
        margin: 10px 0px;
    }

    .detailsProject {
        width: 90%;
        font-size: 16px;
        margin: 0px 0px 0px 0px;
    }

    .bannerpic {
        height: 350px;
        object-fit: cover;
    }

    .title-project-container {
        width: 90%;
        margin: 30px 0px 45px 0px;
    }
    
    .title-02-project {
        display: flex;
        width: 20%;
        font-size: 15px;
        margin-top: 3px;
    }
    
    .comments-project {
        width: 90%;
        font-size: 16px;
        margin: 30px 0px 0px 0px;
    }

    .sneakpic-02 {
        width: 92%;
        margin-bottom: 80px;
    }

    .projectPlayIcon {
        font-size: 17px;
        bottom: 70px; 
        right: 20px; 
    }

    .projectPauseIcon {
        font-size: 17px;
        bottom: 41px; 
        right: 20px; 
    }

    .projectSpeakerIcon {
        font-size: 16px;
        bottom: 13px; 
        right: 19px; 
    }
    .facebook {
        font-size: 16px;
    }
    
    .twitter {
        font-size: 16px;
    }
    
    .whatsapp {
        font-size: 16px;
    }
    
    .telegram {
        font-size: 16px;
    }
    
    .linkedin {
        font-size: 16px;
    }
    
    .credits-container {
        width: 90%;
        margin: 0px 0px 30px 0px;
    }
    
    .creditsTitle {
        margin-bottom: 15px;
    }
    
    .credits {
        font-family: "NON-Natural-Grotesk-Light";
        font-weight: bold;
        font-size: 17px;
        padding-bottom: 10px;
    }
    
    .creditsIcon {
        font-size: 17px;
    }
    
    .creditsText {
        font-size: 16px;
        padding: 3px 0px;
    }

}