@font-face {
  font-family: 'NON-Natural-Grotesk-Bold';
  src: url(./fonts/NON-Natural-Grotesk-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'NON-Natural-Grotesk-Light';
  src: url(./fonts/NON-Natural-Grotesk-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'NON-Natural-Grotesk-Medium';
  src: url(./fonts/NON-Natural-Grotesk-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'NON-Natural-Grotesk-Regular';
  src: url(./fonts/NON-Natural-Grotesk-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NON-Natural-Grotesk-Thin';
  src: url(./fonts/NON-Natural-Grotesk-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Schear-Grotesk-Bold';
  src: url(./fonts/Schear-Grotesk-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Schear-Grotesk-Medium';
  src: url(./fonts/Schear-Grotesk-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Schear-Grotesk-SemiBold';
  src: url(./fonts/Schear-Grotesk-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Schear-Grotesk';
  src: url(./fonts/Schear-Grotesk.ttf) format('truetype');
}

@font-face {
  font-family: 'ABC-Monument-Grotesk-Light';
  src: url(./fonts/ABC-Monument-Grotesk-Light.ttf) format('truetype');
}

.font-NON-Natural-Grotesk-Bold {font-family: NON-Natural-Grotesk-Bold;}
.font-NON-Natural-Grotesk-Light {font-family: NON-Natural-Grotesk-Light;}
.font-NON-Natural-Grotesk-Medium {font-family: NON-Natural-Grotesk-Medium;}
.font-NON-Natural-Grotesk-Regular {font-family: NON-Natural-Grotesk-Regular;}
.font-NON-Natural-Grotesk-Thin {font-family: NON-Natural-Grotesk-Thin;}
.font-Schear-Grotesk-Bold {font-family: Schear-Grotesk-Bold;}
.font-Schear-Grotesk-Medium {font-family: Schear-Grotesk-Medium;}
.font-Schear-Grotesk-SemiBold {font-family: Schear-Grotesk-SemiBold;}
.font-Schear-Grotesk {font-family: Schear-Grotesk;}
.font-ABC-Monument-Grotesk-Light {font-family: ABC-Monument-Grotesk-Light;}

body {
  margin: 0;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
  background-color: black;
}

.menu-floating {
  display: flex;
  position: fixed;
  align-items: flex-start;
  top: 0px;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 8888;

  background-image: url('/src/images/menu/background.webp');
  background-size: 100%;
  background-repeat: no-repeat;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

@media (max-width: 1024px) {
  .menu-floating {
    background-image: url('/src/images/menu/background_movil.webp');
    top: 80px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

.menu-transition-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.menu-transition-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: opacity 300ms ease, transform 300ms ease;
}

.menu-transition-exit {
  opacity: 1;
  transform: translateY(0%);
}

.menu-transition-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 500ms ease, transform 500ms ease;
}

a {
  text-decoration: none;
}