

.contactUsContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 190vh;
    margin: auto;
  
    background-image: url(../../images/contactus/background.webp);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.blobs-01-Container {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
  
    background-image: url(../../images/contactus/blobs.webp);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.heart_umbra {
    display: flex;
    position: absolute;
    top: 37vh;
    left: 0vw;
    width: 34vh;
    height: auto;
    z-index: 2;
    margin-left: 5vw;
    user-select: none;
}

.titleContactUs {
    display: flex;
    font-family: "Schear-Grotesk-Bold";
    font-size: 5.16vw;
    color: #000;
    margin: 5vh 0vw 0vh auto; 
    margin-right: 6.1vw;
}

.subTitleContactUs{
    display: flex;
    width: 40%;
    font-size: 1.67vw;
    font-family: "NON-Natural-Grotesk-light";
    font-weight: normal;
    margin: 2.10vh 0vw 0vh auto; 
    margin-right: 5vw;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 5vh 0vw 0vw auto; 
    margin-right: 5.1vw;
}

.fieldsContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 10vh;
    margin-bottom: 4vh;
}

.messageContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 12vh;
    margin-bottom: 4vh;
}
  
input, textarea {
    padding: 0.5vw;
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    background-color: transparent;
    font-family: "NON-Natural-Grotesk-light";
    font-size: 1.6vw;
    resize: none;
    height: 4vh;
}

span {
    padding: 0.5vw;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "NON-Natural-Grotesk-light";
    font-size: 1.6vw;
    color: red;
}
  
input::placeholder,  textarea::placeholder {
    color: #000;
}
  
button {
    padding: 1.2vh 1.2vw;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: "NON-Natural-Grotesk-light";
    font-size: 1.5vw;
}
  
button:hover {
    background-color: #4B4B4B;
}

.titleOthers {
    display: flex;
    font-family: "Schear-Grotesk";
    font-size: 3vw;
    letter-spacing: 0.4em;
    align-self: flex-start;
    color: #000;
    margin: 0vh 0vw 0vh 4vw;
    width: 30%;
}

.titleInquiries {
    display: flex;
    font-family: "Schear-Grotesk";
    font-size: 3vw;
    letter-spacing: 0.4em;
    align-items: flex-start;
    color: #000;
    margin: 0vh 0vw 0vh 4vw;
    width: 30%;
}

.emailContact {
    display: flex;
    font-family: "NON-Natural-Grotesk-light";
    font-size: 1vw;
    align-items: flex-start;
    color: #000;
    margin: 2vh 0vw 0vh 4vw;
    width: 30%;
}

.titleAddress{
    display: flex;
    flex-direction: row;
    font-family: "NON-Natural-Grotesk-light";
    font-weight: bold;
    hyphens: auto;
    width: 40%;
    align-items: flex-start;
    font-size: 1vw;
    margin: 3vh 0vw 0vh 4vw;
}
.subTitleAddress{
    display: flex;
    flex-direction: row;
    font-family: "NON-Natural-Grotesk-light";
    font-weight: normal;
    hyphens: auto;
    width: 40%;
    align-items: flex-start;
    font-size: 1vw;
    color: inherit;
    margin: 0vh 0vw 0vh 4vw;
}

.umbra {
    position: absolute;
    bottom: -8.6vw;
    right: 3vw;
    height: auto;
    width: 8vw;
    z-index: 2;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .umbra {
        bottom: -108px;
        right: 3vw;
        height: auto;
        width: 100px;
    }
}


/* movil */

@media only screen and (max-width: 1024px) {

    .contactUsContainer {
        height: 1200px;
    }

    .blobs-01-Container {
        height: 85%;
        background-image: url(../../images/contactus/blobs_movil_01_v2.png);
        background-size: 100% 100%;
    }

    .heart_umbra {
        display: none;
    }

    .titleContactUs {
        width: 90%;
        font-size: 40px;
        margin: 20px 20px 0px 20px;
        justify-content: center;
    }

    .subTitleContactUs{
        width: 90%;
        font-size: 17px;
        margin: 20px 20px 0px 20px;
    }

    .contact-form {
        width: 90%;
        margin: 20px 20px 20px 20px;
        justify-content: center;
    }

    .fieldsContainer {
        margin-bottom: 0px;
        height: 50px;
    }

    .messageContainer {
        margin-bottom: 0px;
        height: 70px;
    }

    input, textarea {
        font-size: 17px;
        justify-content: center;
        height: 20px;
    }

    span {
        font-size: 14px;
    }

    .button {
        font-size: 20px;
    } 

    .titleOthers {
        width: 100%;
        font-size: 30px;
        margin: 10px auto 0px 0px; 
        margin-left: 20px;
    }
    
    .titleInquiries {
        width: 100%;
        font-size: 30px;
        margin: 0px auto 0px 0px; 
        margin-left: 20px;
    }

    .titleAddress{
        width: 100%;
        align-self: center;
        font-size: 15px;
        margin: 20px auto 0px 0px;
        margin-left: 20px;
    }

    .subTitleAddress{
        width: 100%;
        align-self: center;
        font-size: 13px;
        margin: 0px auto 0px 0px;
        margin-left: 20px;
    }

    .emailContact{
        width: 100%;
        align-self: center;
        font-size: 15px;
        margin: 20px auto 0px 0px;
        margin-left: 20px;
    }
    
}